import React from 'react'

const Resources = () => {
	return (
		<div className='my-5'>
			<h4>This page is intended for developer resources</h4>
			<p>
				Basically a page for me to organize things from my 'Dev Tools' folder
			</p>
		</div>
	)
}

export default Resources

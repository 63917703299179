import React, { useState, useEffect, useRef } from 'react'
import RINGS from 'vanta/dist/vanta.rings.min'
// Make sure window.THREE is defined, e.g. by including three.min.js in the document head using a <script> tag
//fog, net, rings

const VantaComponent = ({ children }) => {
  const [vantaEffect, setVantaEffect] = useState(null)
  const myRef = useRef(null)
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        RINGS({
          el: myRef.current,
          //rings
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          backgroundColor: 0x000000,
          color: 0x73d600
          // fog (black and gold)
          // mouseControls: true,
          // touchControls: true,
          // gyroControls: false,
          // minHeight: 200.00,
          // minWidth: 200.00,
          // midtoneColor: 0xededed,
          // lowlightColor: 0x6f5e1b,
          // baseColor: 0x362828,
          // speed: 0.90
        })
      )
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])
  return (
    <div ref={myRef} className="vantaBG">
      {children}
    </div>
  )
}

export default VantaComponent

import React from 'react'
import ProjectStyle from '../Components/Projects/ProjectStyles'
import Samples from '../Components/Projects/Samples'

const Projects = () => {
	return (
		<ProjectStyle>
			<Samples />
		</ProjectStyle>
	)
}

export default Projects

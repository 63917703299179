import React from 'react'
import HomeStyles from './HomeStyles'
import About from '../Components/Home/About'
import { motion } from 'framer-motion'

const Home = () => {
  return (
    <HomeStyles>
      <div className="col-8 my-4">
        <h1 className="my-2">
          <span>Building</span> <span>digital</span> <span>magic</span>
          <span>,</span> <span>one</span> <span>pixel</span> <span>at</span>{' '}
          <span>a</span> <span>time</span>
          <span>.</span>
        </h1>
      </div>
      <div className="white-line" />
      <div className="col-12 d-flex justify-content-center">
        <About />
      </div>
    </HomeStyles>
  )
}

export default Home

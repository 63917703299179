import React from 'react'
import FooterStyles from './FooterStyles'


const Footer = () => {
	return (
		<FooterStyles className='text-center py-3'>Adk1ns.com, 2022</FooterStyles>
	)
}

export default Footer

import './App.css'
// import 'bootswatch/dist/sandstone/bootstrap.min.css'
import { Routes, Route } from 'react-router-dom'
import GlobalStyles from './Components/GlobalStyles/GlobalStyles'
import Nav from './Components/Header/Nav'
import Home from './Pages/Home'
import Footer from './Components/Footer/Footer'
import ColorTools from './Components/ColorTools/ColorTools'
import Resources from './Pages/Resources'
import Projects from './Pages/Projects'
import VantaComponent from './Components/Home/VantaComponent'
import ProProjects from './Components/Projects/ProProjects'

function App() {
  return (
    <GlobalStyles>
      <VantaComponent>
        <header>
          <Nav />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="Resources" element={<Resources />} />
            <Route path="Samples" element={<Projects />} />
            <Route path="ColorTools" element={<ColorTools />} />
            <Route path="Pro" element={<ProProjects />} />
          </Routes>
        </main>
        <Footer />
      </VantaComponent>
    </GlobalStyles>
  )
}

export default App

import React from 'react'
// import { Link } from 'react-router-dom'
import NavStyle from './NavStyle'

const Nav = () => {
  return (
    <NavStyle>
      <h2 className="pt-4 mx-4 nav-header">Adk1ns.com</h2>
    </NavStyle>
  )
}

export default Nav

import React from 'react'
import dgtl from '../../Images/dgtlsq.png'
import gorilla from '../../Images/gorilla.jpg'
import paletteman from '../../Images/paintPalette.svg'
import Project from './Project'
import styled from 'styled-components'
import cyberSquares from '../../Images/squares.png'
import bt from '../../Images/TogetherIcon.png'
import balloon from '../../Images/balloon.png'
import sol from '../../Images/sol.png'
import ProStyle from './ProStyle'

const ProProjects = () => {
  return (
    <ProStyle>
      <h1 className="text-center mt-2 mt-md-1">Pro Projects</h1>
      <div className="d-flex justify-content-around flex-wrap">
        <Project
          name="Solaris"
          subHeader="Solaris event planning website"
          image={sol}
          alternativeText="Solaris"
        />
        <Project
          name="Cyber Squares"
          subHeader="A puzzle game built in React"
          image={cyberSquares}
          alternativeText="Cyber Squares"
        />

        <Project
          name="ColorPop"
          subHeader="Color tools for building web projects"
          image={balloon}
          alternativeText="Color picker app"
        />
        <Project
          name="DGTL"
          subHeader="Interactive digital solutions"
          image={dgtl}
          alternativeText="DGTL"
        />
        <Project
          name="Better Together"
          subHeader="Success management platform"
          image={bt}
          alternativeText="Better Together"
        />
        <Project
          name="Léo Devereaux"
          subHeader="Art Gallery"
          image={gorilla}
          alternativeText="Leo's art gallery"
        />
      </div>
    </ProStyle>
  )
}

export default ProProjects

import styled from 'styled-components'
//https://api.quotable.io/random
//http://api.icndb.com/jokes/random
const InspirationStyles = styled.div`
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 1rem;
	border: 5px solid black;
	font-size: 1.2rem;
	color: black;
`

export default InspirationStyles

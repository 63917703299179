import React from 'react'
import { useState } from 'react'
import ProjectStyle from './ProjectStyles'

const Project = ({ name, subHeader, image, alternativeText }) => {
  return (
    <ProjectStyle>
      <div className="col-4">
        <img src={image} alt={alternativeText} />
      </div>
      <div className="col-8 info">
        <h3>{name}</h3>
        <p>{subHeader}</p>
      </div>
    </ProjectStyle>
  )
}

export default Project

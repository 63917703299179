import styled from 'styled-components'

const DictionaryStyles = styled.div`
	background-color: rgba(255, 255, 255, 0.9);
	border-radius: 1rem;
	border: 5px solid black;
	font-size: 1.2rem;

	.counter-padding {
		margin-bottom: -1rem;
	}

	.search-input {
		border-top: none;
		border-left: none;
		border-right: none;
		width: 10rem;
		outline: none;
		background: none;
	}
`

export default DictionaryStyles

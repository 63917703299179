import styled from 'styled-components'

const ProStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
  margin: 2rem;
  padding: 2rem;
  background-color: rgb(0%, 0%, 0%, 65%);
  color: white;
  font-family: 'comfortaa-bold';
  height: 45rem;

  @media (max-width: 768px) {
    height: 60rem;
    margin: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding: 0.25rem;
  }
`

export default ProStyle

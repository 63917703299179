import React from 'react'
import styled from 'styled-components'

const ContactStyles = styled.div`
  transform: skew(-30deg);
  width: 80%;

  input,
  textarea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(0%, 0%, 0%, 65%);
    border: 1px solid white;
    margin-bottom: 1rem;
    padding: 1rem;
    margin: 1rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
    min-width: 5rem;
    color: white;
  }
  textarea {
    width: 95%;
  }

  h2,
  p {
    transform: skew(0deg);
    text-align: center;
  }
`

const Contact = () => {
  return (
    <ContactStyles>
      <p>How can we help you?</p>
      <div className="">
        <input type="text" placeholder="Name" />
        <input type="text" placeholder="Email" />
      </div>
      <textarea placeholder="Message" />
    </ContactStyles>
  )
}

export default Contact

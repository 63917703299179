import styled from 'styled-components'

const NavStyle = styled.div`
  background-color: rgb(0%, 0%, 0%, 85%);
  width: 100%;
  font-family: 'comfortaa-bold';

  ${
    '' /* position: -webkit-sticky;
	position: sticky;
	top: 0;
	left: 0; */
  }

  .link-nav {
    color: white;
    font-size: 1.4em;
    font-weight: bold;
    position: relative;
    top: 0.5rem;
    transition: color 0.75s ease;
    &:hover {
      color: orange;
      text-decoration: none;
    }
    @media screen and (max-width: 420px) {
      font-size: 1.2em;
    }
  }

  .nav-header {
    color: white;
    transition: color 0.75s ease;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 400px) {
      font-size: 2.2em;
    }

    &:hover {
      color: orange;
    }
  }

  @media screen and (max-width: 350px) {
    .link-nav {
      font-size: 1em;
    }
    .nav-header {
      font-size: 1.72em;
      margin-top: 0.15rem;
    }
  }
`

export default NavStyle

import React from 'react'
import styled from 'styled-components'
import Contact from './Contact'

const AboutStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(0%, 0%, 0%, 65%);
  border: 2px solid white;
  border-radius: 5px;
  margin: 2rem;
  transform: skew(30deg);
  width: 60%;
  padding: 1rem;
  padding-left: 2.25rem;
  padding-right: 2.25rem;
  ${'' /* height: 17vh; */}

  h3 {
    cursor: pointer;
    font-family: 'comfortaa-bold';
    &:hover {
      color: orange;
    }
  }

  a,
  h2,
  h3,
  p {
    transform: skew(-30deg);
    color: white;
  }

  @media (max-width: 768px) {
    transform: skew(10deg);
    margin-left: 0;
    margin-right: 0;
    width: 98%;
    h3 {
      font-size: 1.5em;
    }
    h3,
    p,
    a {
      transform: skew(-10deg);
    }
  }
`

const About = () => {
  const [viewState, setViewState] = React.useState('About')

  return (
    <AboutStyles>
      <div className="d-flex mb-2">
        <h3 onClick={() => setViewState('About')}>About</h3>
        <h3 onClick={() => setViewState('Contact')} className="mx-4">
          Contact
        </h3>
        <h3 onClick={() => setViewState('Latest')}>Latest</h3>
      </div>
      {viewState === 'About' && (
        <>
          <p>Custom built digital products.</p>
          <div className="row d-flex justify-content-around">
            <p>Websites</p>
            <p className="mx-3">Games</p>
            <p className="mr-3">Apps</p>
          </div>
        </>
      )}
      {viewState === 'Contact' && (
        <>
          {/* <Contact /> */}
          <p>
            <a href="mailto:jeredkins@gmail.com" target="_blank">
              jeredkins@gmail.com
            </a>
          </p>
          <div className="row d-flex justify-content-around">
            <a
              href="https://www.linkedin.com/in/jered-adkins-886457112/"
              target="_blank"
              rel="noreferrer"
              className="mr-3"
            >
              LinkedIn
            </a>
            <p>
              <a
                href="https://github.com/Adk1ns"
                target="_blank"
                rel="noreferrer"
                className="mr-3"
              >
                Github
              </a>
            </p>
          </div>
        </>
      )}
      {viewState === 'Latest' && (
        <>
          <div className="row d-flex justify-content-around">
            <p>Better Together</p>
            {window.innerWidth > 768 && (
              <p className="mx-4">
                <a
                  href="https://fridge-magnets.click/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Fridge Magnets
                </a>
              </p>
            )}
          </div>
          <div className="row d-flex justify-content-around">
            <a
              href="https://dgtlworkshop.com/"
              target="_blank"
              rel="noreferrer"
            >
              DGTL
            </a>
            <p className="mx-4">
              <a
                href="https://solaris.agency/"
                target="_blank"
                rel="noreferrer"
              >
                Solaris
              </a>
            </p>
            <a href="https://colorpop.studio/" target="_blank" rel="noreferrer">
              ColorPop
            </a>
          </div>
        </>
      )}
    </AboutStyles>
  )
}

export default About

import styled from 'styled-components'

const HomeStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  min-height: 85vh;

  h1 {
    font-size: 7em;
    ${'' /* letter-spacing: -0.85rem; */}
    ${'' /* font-family: righteous; */}
    min-height: 57vh;
    font-family: 'comfortaa-bold';
  }

  h1,
  h2,
  h3,
  h4,
  a {
    color: white;
    transition: color 0.75s ease;
    font-famil: comfortaa-bold;
  }

  p {
    color: white;
    font-family: 'comfortaa-bold';
  }

  a {
    text-decoration: none;
    &:hover {
      color: orange;
    }
  }

  @media (max-width: 768px) {
    h1 {
      font-size: 4em;
    }
  }

  span {
    transition: color 0.75s ease;
    &:hover {
      color: orange;
    }
  }
`

export default HomeStyles

import styled from 'styled-components'

const GlobalStyles = styled.div`
  ${'' /* min-height: 100vh; */}
  ${'' /* overflow-x: hidden; */}
  background-color: black;

  .cursor-no {
    cursor: not-allowed;
  }

  .white-line {
    background-color: white;
    width: 85%;
    height: 2px;
    border-radius: 5px;
    align-self: center;
  }

  .vantaBG {
    overflow-x: hidden;
    overflow-y: hidden;
  }
`

export default GlobalStyles

import styled from 'styled-components'

const ProjectStyle = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 40%;

  border: 1px solid white;
  margin: 1rem;
  img {
    height: 10rem;
  }

  .info {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    margin: 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding: 0.25rem;

    img {
      height: 5rem;
    }
  }
`

export default ProjectStyle
